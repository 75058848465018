import { Component, Input, OnInit } from '@angular/core';
import { DefaultCodeProvider } from '../../../_services/custom-code-providers/default-code-provider';
// @ts-ignore
import { DEFAULT_HYDROCRACKER_MODEL } from '../../../_config/unit-operations/default-models/default-hydrocracker-model';
import { Hydrocracker } from '../../../_models/_unit-operations/hydrocracker';
import { FlowsheetService } from '../../../_services/flowsheet.service';

@Component({
  selector: 'sob-hydrocracker',
  templateUrl: './hydrocracker.component.html',
  styleUrls: ['./hydrocracker.component.css'],
})
export class HydrocrackerComponent implements OnInit {
  @Input()
  unitOperation: Hydrocracker;

  defaultCodeProvider: DefaultCodeProvider;

  constructor(private flowsheetService: FlowsheetService) {}

  ngOnInit() {
    this.defaultCodeProvider = new DefaultCodeProvider(DEFAULT_HYDROCRACKER_MODEL);
  }

  saveCustomModel(res: any) {
    if (res.instanceId === 'hcCodeEditor') {
      this.unitOperation.modelScript = res.code;
      this.flowsheetService.saveCustomCodeChanges();
    }
  }
}
