import { Component, Input, OnInit } from '@angular/core';
import { GasContributorUnitOperation } from '../../../_models/_unit-operations/gas-contributor-unit-operation';
import { DefaultCodeProvider } from '../../../_services/custom-code-providers/default-code-provider';
import { DEFAULT_GAS_CONTRIBUTOR_MODEL } from '../../../_config/unit-operations/default-models/default-gas-contributor-model';
import { CoreService } from '../../../_services/core.service';
import { GasFluidAnalysis } from '../../../_models/_fluid/gas-fluid-analysis';
import { FlowsheetService } from '../../../_services/flowsheet.service';

@Component({
  selector: 'sob-gas-contributor',
  templateUrl: './gas-contributor.component.html',
  styleUrls: ['./gas-contributor.component.css'],
})
export class GasContributorComponent implements OnInit {
  @Input() unitOperation: GasContributorUnitOperation;
  defaultCodeProvider: DefaultCodeProvider;
  isOpened = false;
  currentAnalysis: GasFluidAnalysis;

  constructor(private coreService: CoreService, private flowsheetService: FlowsheetService) {}

  ngOnInit(): void {
    this.defaultCodeProvider = new DefaultCodeProvider(DEFAULT_GAS_CONTRIBUTOR_MODEL);
    this.updateCurrentAnalysis();
  }

  saveGasCustomModel(res: any) {
    if (res.instanceId === 'gasCodeEditor') {
      this.unitOperation.gasModelScript = res.code;
      this.flowsheetService.saveCustomCodeChanges();
    }
  }

  switchOpened() {
    $('#gasDiv').collapse('toggle');
    this.isOpened = !this.isOpened;
  }

  get fluidAnalyses() {
    return this.coreService.currentCase.assayManager.getAssaysByCategory('Gas');
  }

  updateCurrentAnalysis(id?) {
    if (id) {
      this.unitOperation.gasAnalysisId = id;
    }
    if (this.unitOperation.gasAnalysisId) {
      this.currentAnalysis = this.coreService.currentCase.assayManager.findFluidAnalysisById(
        this.unitOperation.gasAnalysisId
      );
    }
  }
}
