import { Component, Input, OnInit } from '@angular/core';
import { SteamUseUnitOperation } from '../../../_models/_unit-operations/steam-use-unit-operation';
import { DefaultCodeProvider } from '../../../_services/custom-code-providers/default-code-provider';
import { DEFAULT_STEAM_MODEL } from '../../../_config/unit-operations/default-models/default-steam-model';
import { FlowsheetService } from '../../../_services/flowsheet.service';

@Component({
  selector: 'sob-steam-use-utility',
  templateUrl: './steam-use-utility.component.html',
  styleUrls: ['./steam-use-utility.component.css'],
})
export class SteamUseUtilityComponent implements OnInit {
  @Input()
  unitOperation: SteamUseUnitOperation;

  defaultCodeProvider: DefaultCodeProvider;
  isOpened = false;
  constructor(private flowsheetService: FlowsheetService) {}

  ngOnInit() {
    this.defaultCodeProvider = new DefaultCodeProvider(DEFAULT_STEAM_MODEL);
  }

  saveSteamCustomModel(res: any) {
    if (res.instanceId === 'steamCodeEditor') {
      this.unitOperation.steamModelScript = res.code;
      this.flowsheetService.saveCustomCodeChanges();
    }
  }

  switchOpened() {
    $('#steamDiv').collapse('toggle');
    this.isOpened = !this.isOpened;
  }
}
