export const environment = {
  production: true,
  apiBaseUrl: 'https://suncor-optea.com/api',
  apiUrl: 'https://suncor-optea.com/api/simulation',
  simulationAsync: 'https://suncor-optea.com/api/solveAsync',
  authUrl: 'https://suncor-optea.com/api/auth',
  usersUrl: 'https://suncor-optea.com/api/users',
  accountUrl: 'https://suncor-optea.com/api/account',
  sendCaseUrl: 'https://suncor-optea.com/api/sendCaseToPe',
  sendFrontendErrorDetailsToPe: 'https://suncor-optea.com/api/send-frontend-error-details-to-pe',
  manualUrl: 'https://suncor-optea.com/api/downloadManual',
  uncertaintyUrl: 'https://suncor-optea.com/api/UncertaintyAnalysis',
  parametricStudyUrl: 'https://www.suncor-optea.com/api/ParametricStudy',
  parametricStudyAsyncUrl: 'https://www.suncor-optea.com/api/solveParametricStudyAsync',
};
