import { Component, Input } from '@angular/core';
import { DCU } from '../../../_models/_unit-operations/dcu';
import { DefaultCodeProvider } from '../../../_services/custom-code-providers/default-code-provider';
// @ts-ignore
import { defaultDCUModel } from '../../../_config/unit-operations/default-models/default-dcu-model';
import { FlowsheetService } from '../../../_services/flowsheet.service';

@Component({
  selector: 'sob-dcu',
  templateUrl: './dcu.component.html',
  styleUrls: ['./dcu.component.css'],
})
export class DCUComponent {
  @Input()
  unitOperation: DCU;

  defaultCodeProvider: DefaultCodeProvider;
  constructor(private flowsheetService: FlowsheetService) {
    this.defaultCodeProvider = new DefaultCodeProvider(defaultDCUModel);
  }

  saveCustomModel(res) {
    if (res.instanceId === 'dcuCodeEditor') {
      this.unitOperation.modelScript = res.code;
      this.flowsheetService.saveCustomCodeChanges();
    }
  }
}
