import { Injectable } from '@angular/core';
import * as go from 'gojs';

@Injectable({ providedIn: 'root' })
export class OtherDiagramObjectsTemplateProvider {
  $: any = go.GraphObject.make;

  // region other diagram objects
  // TODO this could be in a separate file
  getDiagramCommentsTemplate() {
    // the shape of the comments block
    go.Shape.defineFigureGenerator('File', (shape, w, h) => {
      const geo = new go.Geometry();
      const fig = new go.PathFigure(0, 0, true); // starting point
      geo.add(fig);
      fig.add(new go.PathSegment(go.PathSegment.Line, w - 10, 0));
      fig.add(new go.PathSegment(go.PathSegment.Line, w, 10));
      fig.add(new go.PathSegment(go.PathSegment.Line, w, h));
      fig.add(new go.PathSegment(go.PathSegment.Line, 0, h).close());
      const fig2 = new go.PathFigure(w - 10, 0, false);
      geo.add(fig2);
      // The Fold
      fig2.add(new go.PathSegment(go.PathSegment.Line, w - 10, 10));
      fig2.add(new go.PathSegment(go.PathSegment.Line, w, 10));
      geo.spot1 = new go.Spot(0, 0.25);
      geo.spot2 = go.Spot.BottomRight;
      return geo;
    });

    return this.$(
      go.Node,
      'Auto',
      {
        minSize: new go.Size(150, 80),
      },
      this.$(go.Shape, 'File', {
        fill: '#fefeb8',
        strokeWidth: 1,
        spot1: new go.Spot(0, 0),
        spot2: new go.Spot(1, 1),
      }),
      this.$(
        go.TextBlock,
        {
          margin: 10,
          maxSize: new go.Size(250, NaN),
          wrap: go.TextBlock.WrapFit,
          editable: true,
          minSize: new go.Size(130, 60),
          textEditor: this.getCommentsTextEditor(),
          name: 'TEXT',
        },
        new go.Binding('text').makeTwoWay()
      ),
      new go.Binding('location', 'location', go.Point.parse).makeTwoWay(go.Point.stringify)
    );
  }

  getCommentsTextEditor() {
    const customEditor = new go.HTMLInfo();
    const customTextArea = document.createElement('textarea');

    customEditor.show = (textBlock, diagram, tool) => {
      if (!(textBlock instanceof go.TextBlock)) {
        return;
      }

      const textEditingTool = tool as go.TextEditingTool;

      customTextArea.addEventListener('keydown', e => {
        const { key } = e;
        if (key === 'Tab') {
          e.preventDefault();
          return false;
        }
        if (key === 'Escape') {
          // Cancel on Esc
          tool.doCancel();
          if (tool.diagram) {
            tool.diagram.focus();
          }
        }

        return true;
      });

      // taken from https://gojs.net/latest/extensions/TextEditor.js - very important fix!
      // without this, if user clicks outside the diagram, the current textBlock being edited
      // will remain in edit mode
      // Disallow blur.
      // If the textEditingTool blurs and the text is not valid,
      // we do not want focus taken off the element just because a user clicked elsewhere.
      customTextArea.addEventListener(
        'blur',
        function () {
          if (
            !textEditingTool ||
            textEditingTool.currentTextEditor === null ||
            textEditingTool.state === go.TextEditingTool.StateNone
          ) {
            return;
          }

          customTextArea.focus();

          if (textEditingTool.selectsTextOnActivate) {
            customTextArea.select();
            customTextArea.setSelectionRange(0, 9999);
          }
        },
        false
      );

      customTextArea.value = textBlock.text;
      customTextArea.style.resize = 'none';

      const { scale } = textBlock.diagram;
      const scaledWidth = Math.floor(scale * textBlock.measuredBounds.width);
      const scaledHeight = Math.floor(scale * textBlock.measuredBounds.height);
      customTextArea.style.width = `${scaledWidth + 1}px`;
      customTextArea.style.height = `${scaledHeight + 1}px`;

      const scaledFontSize = Math.floor(scale * 12);
      customTextArea.style.fontSize = `${scaledFontSize}px`;

      customTextArea.style.backgroundColor = '#fefeb8';

      const loc = textBlock.getDocumentPoint(go.Spot.TopLeft);
      const pos = diagram.transformDocToView(loc);
      customTextArea.style.left = `${pos.x - 1}px`;
      customTextArea.style.top = `${pos.y - 1}px`;
      customTextArea.style.position = 'absolute';
      customTextArea.style.zIndex = '100'; // place it in front of the Diagram

      if (diagram.div !== null) {
        diagram.div.appendChild(customTextArea);
      }

      customTextArea.focus();
    };

    customEditor.hide = function (diagram) {
      if (diagram.div.contains(customTextArea)) {
        diagram.div.removeChild(customTextArea);
      }
    };

    customEditor.valueFunction = () => {
      return customTextArea.value;
    };

    return customEditor;
  }
  // endregion
}
