import { Component, Input, OnInit } from '@angular/core';
import { HgoHydrotreater } from '../../../_models/_unit-operations/hgo-hydrotreater';
import { SuncorFluidAnalysis } from '../../../_models/_fluid/suncor-fluid-analysis';
import { FluidAnalysis } from '../../../_models/_fluid/fluid-analysis';
import { CoreService } from '../../../_services/core.service';
import { AssaySelectorHelper } from '../../../_services/property-window/assay-selector.helper';
import { HgoHydroteaterCatalystRun } from '../../../_config/unit-operations/hgo-hydroteater-catalyst-run.enum';
import { DefaultCodeProvider } from '../../../_services/custom-code-providers/default-code-provider';
import { defaultHgoHydrotreaterModel } from '../../../_config/unit-operations/default-models/default-hgo-hydrotreater-model';
import { FlowsheetService } from '../../../_services/flowsheet.service';

@Component({
  selector: 'sob-hgo-hydrotreater',
  templateUrl: './hgo-hydrotreater.component.html',
  styleUrls: ['./hgo-hydrotreater.component.css'],
  providers: [AssaySelectorHelper],
})
export class HgoHydrotreaterComponent implements OnInit {
  @Input() unitOperation: HgoHydrotreater;
  currentAnalysis: SuncorFluidAnalysis;
  HgoHydroTreaterCatalystRun = HgoHydroteaterCatalystRun;
  defaultCodeProvider: DefaultCodeProvider;

  constructor(
    private coreService: CoreService,
    private assaySelectorHelper: AssaySelectorHelper,
    private flowsheetService: FlowsheetService
  ) {
    this.defaultCodeProvider = new DefaultCodeProvider(defaultHgoHydrotreaterModel);
  }

  ngOnInit() {
    this.assaySelectorHelper.setUnitOperation(this.unitOperation);
    this.updateCurrentAnalysis();
  }

  useDataDrivenYieldModelChanged(target: EventTarget): void {
    if ((target as HTMLInputElement).checked) {
      this.unitOperation.naphthaYield.value = undefined;
      this.unitOperation.lgoYield.value = undefined;
      this.unitOperation.hgoYield.value = undefined;
    }
  }

  updateCurrentAnalysis(id?) {
    if (id) {
      this.unitOperation.fluidAnalysisId = id;
    }
    if (this.unitOperation.fluidAnalysisId) {
      this.currentAnalysis = this.coreService.currentCase.assayManager.findFluidAnalysisById(
        this.unitOperation.fluidAnalysisId
      );
      this.assaySelectorHelper.setCurrentAnalysis(this.currentAnalysis);
      this.assayChanged();
    }
  }

  assayChanged(): void {
    this.assaySelectorHelper.assayChanged();
  }

  get fluidAnalyses(): FluidAnalysis[] {
    return this.coreService.currentCase.assayManager.getAssaysByCategory('Product');
  }

  saveCustomModel(res: any) {
    if (res.instanceId === 'hgoHydrotreaterCodeEditor') {
      this.unitOperation.customModelScript = res.code;
      this.flowsheetService.saveCustomCodeChanges();
    }
  }
}
