import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { LgoHydrotreater } from '../../../_models/_unit-operations/lgo-hydrotreater';
import { SuncorFluidAnalysis } from '../../../_models/_fluid/suncor-fluid-analysis';
import { LgoHydroteaterCatalystRun, LgoYieldProfile } from '../../../_config/unit-operations/lgo-hydroteater.enum';
import { DefaultCodeProvider } from '../../../_services/custom-code-providers/default-code-provider';
import { CoreService } from '../../../_services/core.service';
import { SimVarFormBuilder } from '../../../_form-utils/sim-var-form-builder';
import { FlowsheetService } from '../../../_services/flowsheet.service';
import { BaseObjectFormGroupWrapper } from '../../../_form-utils/base-object-form-group-wrapper';
import { FluidAnalysis } from '../../../_models/_fluid/fluid-analysis';
import { SimulationVariable } from '../../../_models';
import { VariableStatus } from '../../../_config/variable-status.enum';

@Component({
  selector: 'sob-lgo-hydrotreater',
  templateUrl: './lgo-hydrotreater.component.html',
  styleUrls: ['./lgo-hydrotreater.component.css'],
})
export class LgoHydrotreaterComponent implements OnInit {
  @Input() unitOperation: LgoHydrotreater;
  @Input() formGroupWrapper: BaseObjectFormGroupWrapper;

  currentAnalysis: SuncorFluidAnalysis;
  LgoHydroTreaterCatalystRun = LgoHydroteaterCatalystRun;
  LgoYieldProfile = LgoYieldProfile;
  defaultCodeProvider: DefaultCodeProvider;

  constructor(
    private coreService: CoreService,
    private fb: UntypedFormBuilder,
    private svfb: SimVarFormBuilder,
    private flowsheetService: FlowsheetService
  ) {}

  ngOnInit(): void {
    this.addControls();
    this.updateCurrentAnalysis();
    this.formGroupWrapper.storeOriginalValue();
  }

  addControls() {
    this.propertyWindowFormGroup.addControl('fluidAnalysisId', this.fb.control(this.unitOperation.fluidAnalysisId));

    // General
    this.propertyWindowFormGroup.addControl('catalystRun', this.fb.control(this.unitOperation.catalystRun));
    this.propertyWindowFormGroup.addControl('yieldProfile', this.fb.control(this.unitOperation.yieldProfile));
    this.propertyWindowFormGroup.addControl('naphthaYield', this.svfb.control(this.unitOperation.naphthaYield));
    this.propertyWindowFormGroup.addControl('lgoYield', this.svfb.control(this.unitOperation.lgoYield));
    this.propertyWindowFormGroup.addControl('hgoYield', this.svfb.control(this.unitOperation.hgoYield));
    this.propertyWindowFormGroup.addControl('totalYield', this.svfb.control(this.unitOperation.totalYield, true));
    this.propertyWindowFormGroup.addControl(
      'totalInletVolume',
      this.svfb.control(this.unitOperation.totalInletVolume, true)
    );
    this.propertyWindowFormGroup.addControl('ghgIntensity', this.svfb.control(this.unitOperation.ghgIntensity));
    this.propertyWindowFormGroup.addControl('ghgEmissions', this.svfb.control(this.unitOperation.ghgEmissions, true));
    this.propertyWindowFormGroup.addControl('useCustomModel', this.fb.control(this.unitOperation.useCustomModel));
    this.propertyWindowFormGroup.addControl('customModelScript', this.fb.control(this.unitOperation.customModelScript));

    // Utilities
    this.propertyWindowFormGroup.addControl('hydrogenUseRate', this.svfb.control(this.unitOperation.hydrogenUseRate));
    this.propertyWindowFormGroup.addControl('hydrogenUse', this.svfb.control(this.unitOperation.hydrogenUse, true));
    this.propertyWindowFormGroup.addControl(
      'h2sProductionRate',
      this.svfb.control(this.unitOperation.h2sProductionRate)
    );
    this.propertyWindowFormGroup.addControl('h2sProduction', this.svfb.control(this.unitOperation.h2sProduction, true));

    // Constraints
    this.propertyWindowFormGroup.addControl(
      'capacity',
      this.svfb.control(this.unitOperation.capacity, false, false, true)
    );
    this.propertyWindowFormGroup.addControl(
      'minimumFlow',
      this.svfb.control(this.unitOperation.minimumFlow, false, false, true)
    );
    this.propertyWindowFormGroup.addControl(
      'maximumSulfurContent',
      this.svfb.control(this.unitOperation.maximumSulfurContent, false, false, true)
    );
    this.propertyWindowFormGroup.addControl(
      'maximumNitrogen',
      this.svfb.control(this.unitOperation.maximumNitrogen, false, false, true)
    );

    this.useCustomModelChanged(this.unitOperation.useCustomModel);
    this.changeVariableStatusToEnableField(this.unitOperation.ghgIntensity);
  }

  get propertyWindowFormGroup() {
    return this.formGroupWrapper.getFormGroup();
  }

  get fluidAnalyses(): FluidAnalysis[] {
    return this.coreService.currentCase.assayManager.getAssaysByCategory('Product');
  }

  updateCurrentAnalysis(id?) {
    if (id) {
      this.unitOperation.fluidAnalysisId = id;
    }
    if (this.unitOperation.fluidAnalysisId) {
      this.currentAnalysis = this.coreService.currentCase.assayManager.findFluidAnalysisById(
        this.unitOperation.fluidAnalysisId
      );
      this.propertyWindowFormGroup.get('fluidAnalysisId').setValue(this.unitOperation.fluidAnalysisId);
      this.formGroupWrapper.storeOriginalValue();
    }
  }

  saveCustomModel(res: any) {
    if (res.instanceId === 'lgoHydrotreaterCodeEditor') {
      this.propertyWindowFormGroup.get('customModelScript').setValue(res.code);
      this.unitOperation.customModelScript = this.propertyWindowFormGroup.get('customModelScript').value;
      this.flowsheetService.saveCustomCodeChanges();
    }
  }

  useCustomModelChanged(checked: boolean) {
    if (checked) {
      this.propertyWindowFormGroup.get('naphthaYield').get('value').disable();
      this.propertyWindowFormGroup.get('lgoYield').get('value').disable();
      this.propertyWindowFormGroup.get('hgoYield').get('value').disable();
      this.propertyWindowFormGroup.get('hydrogenUseRate').get('value').disable();
      this.propertyWindowFormGroup.get('h2sProductionRate').get('value').disable();
    } else {
      this.changeVariableStatusToEnableField(this.unitOperation.naphthaYield);
      this.changeVariableStatusToEnableField(this.unitOperation.lgoYield);
      this.changeVariableStatusToEnableField(this.unitOperation.hgoYield);
      this.changeVariableStatusToEnableField(this.unitOperation.hydrogenUseRate);
      this.changeVariableStatusToEnableField(this.unitOperation.h2sProductionRate);

      this.propertyWindowFormGroup.get('naphthaYield').get('value').enable();
      this.propertyWindowFormGroup.get('lgoYield').get('value').enable();
      this.propertyWindowFormGroup.get('hgoYield').get('value').enable();
      this.propertyWindowFormGroup.get('hydrogenUseRate').get('value').enable();
      this.propertyWindowFormGroup.get('h2sProductionRate').get('value').enable();
    }
  }

  changeVariableStatusToEnableField(simVar: SimulationVariable) {
    if (simVar.variableStatus === VariableStatus.SOLVER_CALCULATED) {
      simVar.variableStatus = VariableStatus.USER_SPECIFIED;
    }
  }

  get useCustomModel() {
    return this.propertyWindowFormGroup.get('useCustomModel').value;
  }
}
