import { Completion } from '@codemirror/autocomplete';
import { baseCodeEditorAutoCompletions } from './base-code-editor-autocompletions';

export class DefaultCodeProvider {
  defaultCode: string;

  constructor(defaultCode: string) {
    this.defaultCode = defaultCode;
  }

  getDefaultCode() {
    return this.defaultCode;
  }

  getCodeCompletions(): Completion[] {
    return baseCodeEditorAutoCompletions;
  }
}

// linter suggest to use an interface instead of a type literal O.o
export interface CodeEditorData {
  code: string;
  defaultCodeProvider: DefaultCodeProvider;
}
